/**
 * These are all runtime dependencies. Any breaking changes
 * will need a migration path first!
 */

import type {} from '@sharefiledev/sharefile-appshell'; // force tsc to resolve this package
import { SolutionInfo } from '../data/clients/solutions/sdk/types';

const prefix = 'urn';
const namespace = 'sfextslot';
const modalNamespace = 'sfmodal';
const owner = 'rubicon-pilet';
const owner2 = 'solutions-pilet';

export const packagesDashboardExtensionSlots = {
	// this extension slot is for demonstration purposes only
	doNotUseDemoSlot: `${prefix}:${namespace}:${owner}:dashboard:demo` as const,
	// this extension slot is for the masthead solutions catalog element
	catalogNavigationButton:
		`${prefix}:${namespace}:${owner2}:solutions-catalog-nav-button` as const,
	//this extension slot is for the custom masthead in the solutions catalog shell
	catalogMasthead: `${prefix}:${namespace}:${owner2}:solutions-catalog-masthead` as const,
};

export interface PackagesDemoExtensionParams {
	message: string;
}

export interface RequestInstallModalOverlayParams {
	solution: SolutionInfo;
}

export const rubiconPiletOverlays = {
	requestInstallModal:
		`${prefix}:${modalNamespace}:${owner2}:request-install-modal` as const,
};

declare module '@sharefiledev/sharefile-appshell' {
	interface PiralCustomExtensionSlotMap {
		[packagesDashboardExtensionSlots.doNotUseDemoSlot]: PackagesDemoExtensionParams;
	}
	interface PiralCustomOverlayOptionsMap {
		[rubiconPiletOverlays.requestInstallModal]: RequestInstallModalOverlayParams;
	}
}
