import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { getLogger } from '../logger';
import { checkASTs, checkLDFlags, checkRoles } from './helpers';
import { RolesAndProvisioningRequirements } from './types';

export function checkRolesAndPreferences(
	app: PiletApi,
	options: RolesAndProvisioningRequirements
) {
	try {
		const userContext = app.sf.piletUserContext.get();
		const roles = userContext.user.Roles;
		const preferences = userContext.account.Preferences;
		return (
			checkASTs(preferences, options.requiredASTs) &&
			checkLDFlags(options.requiredLaunchDarklyFeatureFlags) &&
			checkRoles(roles, options.requiredRoles)
		);
	} catch (err) {
		getLogger().logError(err, {
			customMessage: 'Failed to perform user role and provisioning check',
		});
		return false;
	}
}
