import { OverlayModalOptions } from '@sharefiledev/sharefile-appshell';

export type DefaultModalConfiguration = {
	isFullscreen?: boolean;
	titleText?: string;
	className?: string;
};

const fullScreenStyles = {
	styles: {
		body: {
			height: 'calc(95vh - 86px)',
			margin: '20px 0px',
		},
	},
};

const defaultScreenStyles = {
	style: {
		padding: '50px',
	},
	styles: {
		header: {
			fontSize: '20px',
		},
		body: {
			marginTop: '24px',
		},
	},
};

export function getDefaultModalProps(
	props: DefaultModalConfiguration
): Partial<OverlayModalOptions<any>> {
	return {
		modalProps: {
			destroyOnClose: true,
			width: props.isFullscreen ? '95vw' : '640px',
			title: props.titleText,
			footer: null,
			centered: !!props.isFullscreen,
			className: props.className,
			...(props.isFullscreen ? fullScreenStyles : defaultScreenStyles),
		},
	};
}
