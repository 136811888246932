import React from 'react';
import { Skeleton } from 'antd';
import { NavigationButtonProps } from './NavigationButton';
import { PublisherProfileContentExtensionProps } from './PublisherProfileContentExtension';
import { RequestInstallModalExtensionProps } from './RequestInstallModalExtension';

const LazyPublisherProfileContent = React.lazy(
	() => import('./PublisherProfileContentExtension')
);
export const AsyncLazyPublisherProfileContent: React.FC<
	PublisherProfileContentExtensionProps
> = props => (
	<React.Suspense fallback={<Skeleton />}>
		<LazyPublisherProfileContent {...props} />
	</React.Suspense>
);

const LazyRequestInstallModal = React.lazy(
	() => import('./RequestInstallModalExtension')
);
export const AsyncLazyRequestInstallModal: React.FC<
	RequestInstallModalExtensionProps
> = props => {
	return (
		<React.Suspense fallback={<Skeleton />}>
			<LazyRequestInstallModal {...props} />
		</React.Suspense>
	);
};

const LazyNavigationButton = React.lazy(() => import('./NavigationButton'));
export const AsyncLazyNavigationButton: React.FC<NavigationButtonProps> = props => {
	return (
		<React.Suspense fallback={<Skeleton />}>
			<LazyNavigationButton {...props} />
		</React.Suspense>
	);
};
