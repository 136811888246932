import { ReactNode } from 'react';
import { IconProps } from '@sharefiledev/icons/dist/typings/Icon';
import { Spin } from 'antd';

interface ShareFileSpinnerProps extends IconProps {
	loading: boolean;
	children?: ReactNode;
}

export function ShareFileSpinner({ loading, style, children }: ShareFileSpinnerProps) {
	return (
		<Spin
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				...style,
			}}
			spinning={loading}
		>
			{children}
		</Spin>
	);
}
