import { PiletApi } from '@sharefiledev/sharefile-appshell';
import yaml from 'js-yaml';
import { getApiBaseUrl } from '../../helpers/getApiBaseUrl';
import {
	CreateExecutionParams,
	CreateExecutionResponse,
	Execution,
	GetExecutionParams,
	GetSolutionParams,
	initializeSdkLegacy,
	ListSolutionsParamsLegacy,
	Solution,
	SolutionInfoLegacy,
	SolutionsSdkLegacy,
} from './sdk';

/**
 * Wraps the Solutions Axios SDK (see ./sdk/sdkLegacy.ts)
 */
export class SolutionsClientLegacy {
	private initializedSDK: SolutionsSdkLegacy;

	public Initialize(sdk: SolutionsSdkLegacy) {
		this.initializedSDK = sdk;
	}

	public listSolutions(
		params?: ListSolutionsParamsLegacy
	): Promise<SolutionInfoLegacy[]> {
		return this.initializedSDK.listSolutions(params);
	}

	public async getSolution(params: GetSolutionParams): Promise<Solution> {
		const response = (await this.initializedSDK.getSolution(params)).find(
			x => (x as any).version === '0.0.0'
		);
		const LiteralYamlType = new yaml.Type('!literal', { kind: 'mapping' });
		return {
			...response,
			definition: yaml.load(response.definition, {
				schema: new yaml.Schema([LiteralYamlType]),
			}),
		};
	}

	public createExecution(
		params: CreateExecutionParams
	): Promise<CreateExecutionResponse> {
		return this.initializedSDK.createExecution(params);
	}

	public getExecution(params: GetExecutionParams): Promise<Execution> {
		return this.initializedSDK.getExecution(params);
	}
}

export const solutionsClientLegacy = new SolutionsClientLegacy();

export function configureSolutionsClientLegacy(app: PiletApi) {
	solutionsClientLegacy.Initialize(
		initializeSdkLegacy({
			baseUrl: getApiBaseUrl(app),
		})
	);
}
