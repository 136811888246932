import { useMemo } from 'react';
import { basePath } from '@routes';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { Redirect } from 'react-router-dom';
import { checkRolesAndPreferences } from './evaluateProvisioning';
import { RolesAndProvisioningRequirements } from './types';

export function RenderConditionally(props: {
	piletApi: PiletApi;
	children: any;
	requirements: RolesAndProvisioningRequirements;
	redirectToDashboard?: boolean;
}) {
	const isAllowed = useMemo(() => {
		return checkRolesAndPreferences(props.piletApi, props.requirements);
	}, [props.piletApi, props.requirements]);

	return isAllowed ? (
		<>{props.children}</>
	) : props.redirectToDashboard ? (
		<Redirect to={basePath}></Redirect>
	) : (
		<></>
	);
}
