import React from 'react';
import { ShareFileSpinner } from '@common/CommonStyledComponents/ShareFileSpinner';
import { PiletApi } from '@sharefiledev/sharefile-appshell';

export interface ExtensionProps {
	app: PiletApi;
}

const LazyCatalogBrowseByTypePage = React.lazy(() => import('./CatalogBrowseByTypePage'));
export const AsyncCatalogBrowseByTypePage: React.FC<ExtensionProps> = props => (
	<React.Suspense fallback={<ShareFileSpinner loading />}>
		<LazyCatalogBrowseByTypePage {...props} />
	</React.Suspense>
);
